import * as React from "react"
import styled from "styled-components"
import { StaticImage } from "gatsby-plugin-image"

const HeaderContainer = styled.header`
  background-color: white;
  padding: 21px;
`

const Header = () => (
  <HeaderContainer>
    <div>
      <StaticImage src="../images/logo.png" alt="logo" quality={100} layout="fixed" width={210} />
    </div>
  </HeaderContainer>
)

export default Header
